<template>
  <Cpe />
</template>

<script>
import Cpe from "@/components/ticketsRegistration/cpeCredits";

export default {
  name: "CPEPage",
  components: { Cpe },
  data: () => ({}),
};
</script>
